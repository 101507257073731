import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

import Box from './Box'

const ptr = keyframes`
0%,
50%,
100% {
  transform: translate3d(0,-15%,0);
}

25%,
75% {
  transform: translate3d(0,15%,0);
}
`

const PointingAnimate = styled(Box)`
animation: ${ptr} 2s infinite;
`

export default PointingAnimate
