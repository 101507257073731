import React, { useEffect, useRef, useState } from 'react';
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { CgScrollV } from 'react-icons/cg';

import Box from '../../components/Box';
import Flex from '../../components/Flex';
import Text from '../../components/Text';
import Image from '../../components/Image';
import PointingAnimate from '../../components/PointingAnimate';
import { Media, responsive } from '../../contexts/responsive';

import ta from './ta.svg'
import file from './file.svg'
import notebook from './notebook.svg'
import chart from './chart.svg'
import video from './video.svg'
import paper from './paper.svg'
import click from './click.svg'
import linechart from './linechart.svg'
import house from './house.svg'
import select from './select.svg'
import fb from './fb.svg'
import list from './list.svg'
import survey from './survey.svg'
import group from './group.svg'
import report from './report.svg'
import seo from './seo.svg'
import train from './train.svg'
import design from './design.svg'


const details = {
  溝通策略設定: {
    desc: '透過無數次的線上溝通實驗經驗，設定策略解決溝通問題、達成目標！',
    contents: [
      {
        title: '溝通對象研究',
        text: '不夠瞭解潛在溝通對象嗎？透過各種線上、線下的觀察與訪談畫出輪廓。',
        icon: ta
      },
      {
        title: '資料處理',
        text: '不知道要如何使用資料？讓我們幫你以科學的方式和資料相處。',
        icon: file
      },
      {
        title: '內容設計',
        text: '覺得手上的資訊不夠有趣？讓我們一起創造出客戶會喜歡的故事。',
        icon: notebook
      },
    ]
  },
  內容設計與製作: {
    desc: '透過設計方法，把資訊視覺化，降低理解門檻並增加探索的樂趣！',
    contents: [
      {
        title: '資訊圖表/懶人包',
        text: '一張或一組圖就把事情說清楚，運用在社群上、網站內或是實體印刷皆可',
        icon: chart
      },
      {
        title: '說明動畫',
        text: '讓資訊動起來，60-90秒的影片就能說完一個充實精彩的故事。',
        icon: video
      },
      {
        title: '手冊/成果報告',
        text: '從大量資料中疏理呈現脈絡，搭配資訊圖表，讓成果報告變得更精彩、親民。',
        icon: paper
      },
      {
        title: '互動式網頁/測驗',
        text: '讓使用者主動探索並自己篩選資訊，好的互動設計讓體驗加分。',
        icon: click
      },
      {
        title: '簡報分鏡與製作',
        text: '使你的簡報更有說服力，上台更有自信！',
        icon: linechart
      },
      {
        title: '展場中的資訊圖表',
        text: '當資訊圖表走進立體空間，充實展覽內容與亮點。',
        icon: house
      },
    ]
  },
  社群投放測試: {
    desc: '掌握好的內容與資訊圖表後，讓我們來建議你如何使用、評估成效！',
    contents: [
      {
        title: '社群測試',
        text: '用最低的成本觀察不同溝通方式的表現，作為進階策略的依據。',
        icon: select
      },
      {
        title: '社群投放',
        text: '長期與優質的社群投放和精準分析團隊合作，最大化溝通效益。',
        icon: fb
      },
      {
        title: '檢討調整',
        text: '不斷動態檢討、調整投放策略，完美掌握群眾的反應。',
        icon: list
      },
    ]
  },
  量化分眾分析: {
    desc: '交叉分析行為數據與調查數據，更客觀精準地分眾，以制定適合的會員機制與流量池經營策略',
    contents: [
      {
        title: '客製化品牌溝通前導調查',
        text: '從現有資料分析、溝通策略的命題設定到調查方法設計與執行',
        icon: survey
      },
      {
        title: '為特定溝通需求規劃分眾',
        text: '設計會員制度、品牌互動體驗等，為你的品牌與使用者建立最穩固的關係',
        icon: group
      },
    ]
  },
  "搜尋引擎優化（SEO）": {
    desc: '讓潛在消費者自己上門的重要內容佈局，也是內容行銷的重要基礎建置',
    contents: [
      {
        title: '現有網站SEO健檢分析報告',
        text: '自家網站健檢與競爭對手與潛在機會分析',
        icon: report
      },
      {
        title: 'SEO佈局策略與內容經營',
        text: '無論是全新網頁建立，還是既有平台計畫加強內容提升自然流量，我們都能為你的品牌量身打造對演算法和使用者都友善的內容經營策略',
        icon: seo
      },
    ]
  },
  資訊設計工作坊: {
    desc: '靈感只是好作品的開頭，合作與共創才是好作品最難達成的必要條件',
    contents: [
      {
        mobileTitle: '企業內訓\n【推薦：主管、資深成員】',
        title: '企業內訓【推薦：主管、資深成員】',
        text: '專業產品/行銷團隊提升資訊設計實戰力，從應用情境反推課程內容規劃',
        icon: train
      },
      {
        mobileTitle: '精準設計\n【推薦產業：醫藥健康、投資理財、科技產品等專業資訊傳遞】',
        title: '精準設計【推薦產業：醫藥健康、投資理財、科技產品等專業資訊傳遞】',
        text: '邀請專業與資訊設計師共創，一個下午即能完成作品初稿！',
        icon: design
      },
    ]
  },
}

const Detail = ({ isOpen, onClose }) => {
  const ref = useRef()
  const [canScroll, setScroll] = useState()
  useEffect(() =>{
    setTimeout(() => setScroll(ref.current?.scrollHeight > ref.current?.clientHeight))
  }, [isOpen])
  return (
    <Modal preserveScrollBarGap blockScrollOnMount isOpen={isOpen} isCentered onClose={onClose}>
      <ModalContent border="2px solid" boxShadow={'1px 10px 3px #000'} borderRadius={'2em'} width={responsive('90%', '85%')} maxWidth={responsive('30em', '72em')}>
        <ModalCloseButton
          right="0"
          top="0"
          transform={'translate(15%, -10%)'}
          borderRadius={'full'}
          bg="black"
          color="white"
          fontSize={responsive('1.25em', '2.8125em')}
          width={responsive('2.5rem', '2em')}
          height={responsive('2.5rem', '2em')}
          _hover={{ bg: 'black' }}
        />
        <ModalBody pt="1.875em" pb="2.625em" px="0" mx="1.625em">
          <Box>
            <Text.Bold fontSize={responsive('1.25em', '2.625em')}>{isOpen}</Text.Bold>
            <Text fontSize={responsive('0.875em', '1.875em')} color="textGray" pt="0.5rem">{details[isOpen]?.desc}</Text>
          </Box>
          {details[isOpen]?.contents && (
            <Box ref={ref} height={responsive('45vh', '60vh')} maxHeight={responsive('18.75em', '27em')} overflow={'auto'} mt="0.625em" borderTop={'1px solid'} borderColor="textGray">
              {details[isOpen]?.contents.map((d, i) => (
                <Flex pt={'1.25em'} key={i}>
                  <Box width={responsive('3.25em', '7.25em')} mt={responsive(' 0.5em' , 0)} mr={responsive('0.75em', '1.875em')}><Image src={d.icon} /></Box>
                  <Box flex={1}>
                    <Media lessThan='lg'>
                      <Text.Bold whiteSpace={'pre-wrap'}>{d.mobileTitle || d.title}</Text.Bold>
                    </Media>
                    <Media greaterThanOrEqual='lg'>
                      <Text.Bold fontSize="2em">{d.title}</Text.Bold>
                    </Media>
                    <Text fontSize={responsive('0.875em', '1.75em')} color="textGray">{d.text}</Text>
                  </Box>
                </Flex>
              ))}
            </Box>
          )}
          {canScroll && (
            <Box.Absolute left="50%" transform="translateX(-50%)" bottom="2em">
              <PointingAnimate fontSize={responsive('1.5em', '2em')} color="black">
                <CgScrollV />
              </PointingAnimate>
            </Box.Absolute>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
};

export default Detail;
