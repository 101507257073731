import React, { useState } from 'react';
import styled from '@emotion/styled';

import Box from '../../components/Box';
import Flex from '../../components/Flex';
import Text from '../../components/Text';
import Image from '../../components/Image';
import Button from '../../components/Button';
import Container from '../../components/Container';
import BackgroundImage from '../../components/BackgroundImage';
import { Media, responsive } from '../../contexts/responsive';

import Detail from './Detail';

import service from './service.svg';
import service_desk from './service-desk.svg';
import note from './note.svg';
import note_desk from './note-desk.svg';
import guide from './guide.svg';
import discuss from './discuss.svg';
import SeeMore from '../../components/SeeMore';

const StyledButton = styled(Button.Outline)`
  @media screen and (max-width: 480px) {
    font-size: 3.75vw
  }
`
const StyledText = styled(Text.Bold)`
    @media screen and (max-width: 480px) {
      font-size: 4.8vw;
  }
`
const data = [
  {
    title: '基本資訊設定',
    options: [
      { label: '溝通策略設定', trackerName: 'service_strategy' },
      { label: '內容設計與製作', trackerName: 'service_content' },
      { label: '社群投放測試', trackerName: 'service_testing' },
    ]
  },
  {
    title: '進化溝通實驗',
    options: [
      { label: '量化分眾分析', trackerName: 'service_analytics' },
      { label: '搜尋引擎優化（SEO）', trackerName: 'service_SEO' },
      { label: '資訊設計工作坊', trackerName: 'service_workshop' },
    ]
  },
]

const Service = () => {
  const [isOpen, setOpen] = useState()
  return (
    <Box overflow={'hidden'}>
      <Container maxWidth={responsive('34em', '70.625em')} pt={responsive('2.5em', '6em')}>
        <Flex flexDirection={responsive('row', 'column-reverse')}>
          <Box flex={1} mr={responsive("0.5em", 0)} mt={responsive(0, '2.5em')}>
            <Text.Bold lineHeight="1" fontSize={responsive('1.75em', '3.75em')} textAlign={responsive('left', 'center')}>品牌服務</Text.Bold>
            <Text mt="0.75rem" textAlign={'center'} fontSize={responsive('0.875em', '1.875em')} color="custom.textGray">點擊有興趣的項目，進一步了解服務內容！</Text>
            <Box.Relative mt={responsive('1em', '3.75em')}>
              <Media lessThan='lg'>
                <BackgroundImage src={note} ratio={608 / 885}>
                  <Box pt={responsive('15%')} pl={'10.75%'} pr={'12.1%'}>
                    {data.map((d, i) => (
                      <Box pt={i && '10%'} key={i}>
                        <StyledText lineHeight="1">{d.title}</StyledText>
                        {d.options.map((opt, k) => (
                          <Box pt={'0.625em'} key={k}>
                            <StyledButton onClick={() => setOpen(opt.label)} trackerName={opt.trackerName} width="100%" py="0.25rem">{opt.label}</StyledButton>
                          </Box>
                        ))}
                      </Box>
                    ))}
                  </Box>
                </BackgroundImage>
              </Media>
              <Media greaterThanOrEqual='lg'>
                <BackgroundImage src={note_desk} ratio={1107.27 / 649.41}>
                  <Flex justifyContent={'space-between'} pt={responsive('12.75%')} pl={'5.6%'} pr={'7.4%'}>
                    {data.map((d, i) => (
                      <Box width="48.5%" key={i}>
                        <StyledText fontSize="2.25em" lineHeight="1">{d.title}</StyledText>
                        {d.options.map((opt, k) => (
                          <Box pt={k ? '2.375em' : '2em'} key={k}>
                            <StyledButton fontSize="2em" onClick={() => setOpen(opt.label)} trackerName={opt.trackerName} width="100%" py="0.675rem">{opt.label}</StyledButton>
                          </Box>
                        ))}
                      </Box>
                    ))}
                  </Flex>
                </BackgroundImage>
              </Media>
              <Box.Absolute width={responsive("5.5em", '14.6%')} left={responsive('90%', '92.5%')} bottom={responsive(0, '-11%')}>
                <Image src={guide} />
              </Box.Absolute>
            </Box.Relative>
          </Box>
          <Box width={responsive('12.5%', '51%')} mx="auto">
            <Media lessThan='lg'>
              <BackgroundImage ratio={92.08 / 593.28} src={service} />
            </Media>
            <Media greaterThanOrEqual='lg'>
              <BackgroundImage ratio={593.28 / 92.08} src={service_desk} />
            </Media>
          </Box>
        </Flex>
        <Detail isOpen={isOpen} onClose={() => setOpen(false)} />
      </Container>
      <SeeMore page="service" pt={responsive('2.875em', '10.25em')} />
    </Box>
  )
};

export default Service;
